/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, Autocomplete } from "@mui/material";

// Trakib components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Trakib examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate } from "react-router-dom";

import CrudService from "services/cruds-service";

import i18n from "i18n";

const AddOrder = () => {
  const navigate = useNavigate();

  const { t } = i18n;
  const [jobtitles, setJobTitles] = useState([]);
  const [ordersTypes, setOrdersTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [order, setOrder] = useState({
    order_type_id: null,
    jobtitle_id: null,
    user_id: null,
    step1_user_jobtitle: "",
    details: "",
  });

  const [error, setError] = useState({
    details: false,
    order_type_id: false,
    jobtitle_id: false,
    user_id: false,
    error: false,
    textError: "",
  });

  const [inputs, setInputs] = useState([
    {
      key: "",
      value: "",
    },
  ]);

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        key: "",
        value: "",
      },
    ]);
  };

  const handleChange = (name, index, v) => {
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = v;
    setInputs(onChangeValue);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  useEffect(() => {
    (async () => {
      try {
        const usersRes = await CrudService.getAllData(
          "items/job_title?fields=id,title"
        );
        setJobTitles(usersRes.data);
        const ordersTypesRes = await CrudService.getAllData(
          "items/order_type?fields=id,title,step2_user_jobtitle,details"
        );
        setOrdersTypes(ordersTypesRes.data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (order.order_type_id === "") {
      setError({
        order_type_id: true,
        jobtitle_id: false,
        user_id: false,
        details: false,
        textError: t("required"),
      });
      return;
    }

    if (order.jobtitle_id === "") {
      setError({
        order_type_id: false,
        jobtitle_id: true,
        user_id: false,
        details: false,
        textError: t("required"),
      });
      return;
    }

    if (order.user_id === "") {
      setError({
        order_type_id: false,
        jobtitle_id: false,
        user_id: true,
        details: false,
        textError: t("required"),
      });
      return;
    }
    const newOrder = {
      order_type_id: order.order_type_id.id,
      details: inputs,
      jobtitle_id: order.jobtitle_id.id,
      user_id: order.user_id.user_id.id,
      step1_user_jobtitle: order.user_id.id,
      step2_user_jobtitle: order.order_type_id.step2_user_jobtitle,
    };

    try {
      await CrudService.createItem("items/orders", newOrder);
      navigate("/orders", {
        state: { value: true, text: t("createSuccessfully") },
      });
    } catch (err) {
      setError({ ...error, error: true, textError: err.message });
      return null;
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {t("addOrder")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={submitHandler}
                encType="multipart/form-data"
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <MDBox display="flex" flexDirection="column" marginTop="2rem">
                    <Autocomplete
                      defaultValue={null}
                      options={ordersTypes}
                      getOptionLabel={(option) => {
                        if (option.data) {
                          if (option.data.title) return option.data.title;
                        } else {
                          if (option) {
                            if (option.title) return option.title;
                          }
                        }
                        return "";
                      }}
                      value={order.order_type_id}
                      isOptionEqualToValue={(option, item) =>
                        option === item || option.id === item.id
                      }
                      onChange={async (event, newValue) => {
                        setInputs(newValue.details);
                        setOrder({
                          ...order,
                          order_type_id: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label={t("order-type")}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    {error.order_type_id && (
                      <MDTypography
                        variant="caption"
                        color="error"
                        fontWeight="light"
                        pl={4}
                      >
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox display="flex" flexDirection="column" marginTop="2rem">
                    <Autocomplete
                      defaultValue={null}
                      options={jobtitles}
                      getOptionLabel={(option) => {
                        if (option.data) {
                          if (option.data.title) return option.data.title;
                        } else {
                          if (option) {
                            if (option.title) return option.title;
                          }
                        }
                        return "";
                      }}
                      value={order.jobtitle_id}
                      isOptionEqualToValue={(option, item) =>
                        option === item || option.id === item.id
                      }
                      onChange={async (event, newValue) => {
                        if (newValue) {
                          const userRes = await CrudService.getAllData(
                            `items/users_job_title?fields=id,job_title_id.id,user_id.id,user_id.first_name&filter[job_title_id]=${newValue.id}&filter[status][_eq]=published`
                          );
                          setUsers(userRes.data);
                        }
                        setOrder({
                          ...order,
                          jobtitle_id: newValue,
                          user_id: "",
                          step1_user_jobtitle: "",
                        });
                      }}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label={t("jobtitles")}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    {error.jobtitle_id && (
                      <MDTypography
                        variant="caption"
                        color="error"
                        fontWeight="light"
                        pl={4}
                      >
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>
                  {order.jobtitle_id && (
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null}
                        options={users}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.user_id.first_name)
                              return option.data.user_id.first_name;
                          } else {
                            if (option) {
                              if (option.user_id.first_name)
                                return option.user_id.first_name;
                            }
                          }
                          return "";
                        }}
                        value={order.user_id}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={(event, newValue) => {
                          setOrder({
                            ...order,
                            user_id: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label={t("users")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.user_id && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                  )}
                  {inputs.map((item, index) => (
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between" // Optional: adjust column spacing
                    >
                      <MDBox flex={1} bgcolor="lightblue" p={2}>
                        {/* <FormField
                          label={t("title")}
                          placeholder=""
                          name="key"
                          onChange={(event) =>
                            handleChange("key", index, event.target.value)
                          }
                          value={item.key}
                        /> */}
                        {item.key}
                      </MDBox>
                      <MDBox flex={1} bgcolor="lightgreen" p={2}>
                        <FormField
                          label={t("value")}
                          placeholder=""
                          name="value"
                          onChange={(event) =>
                            handleChange("value", index, event.target.value)
                          }
                          value={item.value}
                        />
                      </MDBox>
                      {/* <MDBox flex={1} bgcolor="lightgreen" p={2}>
                        {inputs.length > 1 && (
                          <MDButton
                            onClick={() => handleDeleteInput(index)}
                            size="large"
                          >
                            -
                          </MDButton>
                        )}
                      </MDBox>
                      <MDBox flex={1} bgcolor="lightgreen" p={2}>
                        {index === inputs.length - 1 && (
                          <MDButton
                            onClick={() => handleAddInput()}
                            size="large"
                          >
                            +
                          </MDButton>
                        )}
                      </MDBox> */}
                    </MDBox>
                    // <Grid
                    //   container
                    //   spacing={3}
                    //   key={Math.random().toString()}
                    //   pt={3}
                    // >
                    //   <Grid item xs={5}>
                    //     <FormField
                    //       label={t("title")}
                    //       placeholder=""
                    //       name="key"
                    //       onChange={(event) =>
                    //         handleChange("key", index, event.target.value)
                    //       }
                    //       value={item.key}
                    //     />
                    //   </Grid>
                    //   <Grid item xs={5}>
                    //     <FormField
                    //       label={t("value")}
                    //       placeholder=""
                    //       name="value"
                    //       onChange={(event) =>
                    //         handleChange("value", index, event.target.value)
                    //       }
                    //       value={item.value}
                    //     />
                    //   </Grid>
                    //   <Grid item xs={1}>
                    //     {inputs.length > 1 && (
                    //       <MDButton
                    //         onClick={() => handleDeleteInput(index)}
                    //         size="large"
                    //       >
                    //         -
                    //       </MDButton>
                    //     )}
                    //   </Grid>
                    //   <Grid item xs={1}>
                    //     {index === inputs.length - 1 && (
                    //       <MDButton
                    //         onClick={() => handleAddInput()}
                    //         size="large"
                    //       >
                    //         +
                    //       </MDButton>
                    //     )}
                    //   </Grid>
                    // </Grid>
                  ))}
                  {error.error && (
                    <MDTypography
                      variant="caption"
                      color="error"
                      fontWeight="light"
                      pt={2}
                    >
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox
                    ml="auto"
                    mt={4}
                    mb={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/orders", {
                            state: { value: false, text: "" },
                          })
                        }
                      >
                        {t("back")}
                      </MDButton>
                    </MDBox>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      type="submit"
                    >
                      {t("save")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AddOrder;
