/** 
  All of the routes for the Trakib are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import Analytics from "layouts/dashboards/analytics";

import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";

import UserProfile from "cruds/user-profile";
import UserManagement from "cruds/user-management";
import Items from "cruds/settings";
import Jobtitles from "cruds/jobtitles";

// Trakib components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-4.jpg";

import i18n from "i18n";
import Programs from "cruds/programs";
import Researches from "cruds/researches";
import Departments from "cruds/departments";
import OrderType from "cruds/order-type";
import Orders from "cruds/orders";
import Works from "cruds/works/works";
import Logs from "cruds/sys-admin/admin-activities";
import Links from "cruds/sys-admin/admin-links";
import Notifications from "cruds/sys-admin/admin-notifications";

const { t } = i18n;

const routes = [
  {
    type: "collapse",
    name: "Bruce Mars",
    key: "user-name",
    icon: <MDAvatar src={profilePicture} alt="Bruce Mars" size="sm" />,
    collapse: [
      {
        name: t("profile"),
        key: "user-profile",
        route: "/examples-api/user-profile",
        component: <UserProfile />,
      },
      {
        name: "Logout",
        key: "logout",
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: t("dashboards"),
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: t("analytics"),
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
    ],
  },
  {
    type: "collapse",
    name: t("systemManagement"),
    key: "system-management",
    icon: <GroupAddOutlinedIcon size="sm" />,
    collapse: [
      {
        name: t("activities"),
        key: "sys-admin",
        route: "/system/logs",
        component: <Logs />,
        type: "system",
      },
      {
        name: t("links"),
        key: "admin-links",
        route: "/system/links",
        component: <Links />,
        type: "link",
      },
      {
        name: t("notifications"),
        key: "admin-notifications",
        route: "/system/notifications",
        component: <Notifications />,
        type: "notification",
      },
    ],
  },
  {
    type: "collapse",
    name: t("userManagement"),
    key: "user-management",
    icon: <GroupAddOutlinedIcon size="sm" />,
    collapse: [
      {
        name: t("users"),
        key: "add-user",
        route: "/userManagement/users",
        component: <UserManagement />,
        type: "users",
      },
    ],
  },
  {
    type: "collapse",
    name: t("settings"),
    key: "settings",
    icon: <EditCalendarOutlinedIcon fontSize="medium" />,
    collapse: [
      {
        type: "collapse",
        name: t("jobtitleSettings"),
        key: "jobtitleSettings",
        icon: <EditCalendarOutlinedIcon fontSize="medium" />,
        collapse: [
          {
            name: t("job_title"),
            key: "job_title",
            route: "/jobTitles",
            component: <Jobtitles />,
            type: "job_title",
          },
          {
            name: t("standards"),
            key: "standards",
            route: "/jobtitles/standards",
            component: <Items />,
            type: "standards",
          },
          {
            name: t("tasks"),
            key: "tasks",
            route: "/jobtitles/tasks",
            component: <Items />,
            type: "tasks",
          },
        ],
      },
      {
        type: "collapse",
        name: t("programsSettings"),
        key: "programsSettings",
        icon: <EditCalendarOutlinedIcon fontSize="medium" />,
        collapse: [
          {
            name: t("programs"),
            key: "programs",
            route: "/programs",
            component: <Programs />,
            type: "programs",
          },
          {
            name: t("research_rankings"),
            key: "research_rankings",
            route: "/programs/research_rankings",
            component: <Items />,
            type: "research_rankings",
          },
          {
            name: t("researches"),
            key: "researches",
            route: "/programs/researches",
            component: <Researches />,
            type: "researches",
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: t("works"),
    key: "worksTitle",
    icon: <EditCalendarOutlinedIcon fontSize="medium" />,
    collapse: [
      {
        name: t("works"),
        key: "works",
        route: "works",
        component: <Works />,
        type: "works",
      },
      {
        name: t("units"),
        key: "units",
        route: "works/units",
        component: <Items />,
        type: "units",
      },
      {
        name: t("departments"),
        key: "departments",
        route: "works/departments",
        component: <Departments />,
        type: "departments",
      },
    ],
  },
  {
    type: "collapse",
    name: t("orders"),
    key: "orders",
    icon: <EditCalendarOutlinedIcon fontSize="medium" />,
    collapse: [
      {
        name: t("orders"),
        key: "orders",
        route: "orders",
        component: <Orders />,
        type: "orders",
      },
      {
        name: t("orderType"),
        key: "order_type",
        route: "orders/order_type",
        component: <OrderType />,
        type: "order_type",
      },
    ],
  },
];

export default routes;
