/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Trakib components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Trakib examples
import DefaultLayout from "examples/LayoutContainers//DefaultLayout";
import DeleteIcon from "@mui/icons-material/Delete";

import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import { Tooltip, IconButton } from "@mui/material";

import EyeIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/Cancel";

import CrudService from "services/cruds-service";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "context";

import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import i18n from "i18n";

import FilterOrder from "./filter-orders";
import Checkbox from "@mui/material/Checkbox";

function Orders() {
  let { state } = useLocation();
  const { t } = i18n;

  const [orders, setOrders] = useState([]);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [sortVal, setSortVal] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [limitChange, setLimitChange] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterUrl, setFilterUrl] = useState("");
  const [filterData, setFilterData] = useState(false);

  const handleChange = async (e, uCancel, id) => {
    if (!uCancel) {
      let payload = {
        [e.target.name]: e.target.checked,
      };
      await CrudService.updateItem(`items/orders/${id}`, payload);
      let sortUrl = "";
      if (sortVal) {
        sortUrl = `&sort=${sortVal.desc ? "-" : "+"}${sortVal.id}`;
      }
      getOrders(`${filterUrl}${sortUrl}`);
    } else {
      alert("تم إلغاء الطلب");
    }
  };

  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });
  const reqUrl = `items/orders?fields=*,user_created.first_name,user_created.last_name,user_created.id,user_canceled.first_name,user_canceled.last_name,user_canceled.id,date_created,count(works),order_type_id.title,step1_user_jobtitle.user_id.first_name,step1_user_jobtitle.user_id.id,step1_user_jobtitle.job_title_id.title,step1_user_jobtitle.job_title_id.id,approve1,step2_user_jobtitle.user_id.first_name,step2_user_jobtitle.user_id.id,step2_user_jobtitle.job_title_id.title,step2_user_jobtitle.job_title_id.id,approve2,step3_user_jobtitle.user_id.first_name,step3_user_jobtitle.user_id.id,step3_user_jobtitle.job_title_id.title,step3_user_jobtitle.job_title_id.id,approve3
  &page=${page}&sort=-id`;

  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      setIsAdmin(await authContext.isCurrentUserAdmin());
      setUserData(await authContext.getCurrentUser());
    })();
  }, []);

  const getOrders = async (filUrl) => {
    const response = await CrudService.getData(`${reqUrl}${filUrl}`);
    setOrders(response.data);
    setMeta(response.meta);
  };

  useEffect(() => {
    if (limitChange) {
      setLimitChange(false);
      return; //to avoid do request two time when it true and false ;now it do only one time when it return to false
    }

    if (filterData) {
      setFilterData(false);
      return; //to avoid do request two time when it true and false ;now it do only one time when it return to false
    }

    let sortUrl = "";
    if (sortVal) {
      sortUrl = `&sort=${sortVal.desc ? "-" : "+"}${sortVal.id}`;
    }
    getOrders(`${filterUrl}${sortUrl}`);
  }, [page, limitChange, sortVal, filterData]);

  useEffect(() => {
    if (page !== 1) setPage(1);
    else if (filterUrl !== "") setFilterData(true); // to do any action to fire useEffect which fire when filterData changed to do getOrders function and aproove that sort value exist
  }, [filterUrl]);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  const clickAddHandler = () => {
    navigate("/orders/add");
  };

  const clickEditHandler = (id) => {
    navigate(`/orders/update/${id}`);
  };

  const getRows = (info) => {
    let updatedInfo = info.map((row) => {
      return {
        id: row.id,
        title: row.order_type_id.title,
        step1: `${row.step1_user_jobtitle.job_title_id?.title}: ${row.step1_user_jobtitle.user_id?.first_name}`,
        step2: `${row.step2_user_jobtitle.job_title_id?.title}:  ${row.step2_user_jobtitle.user_id?.first_name}`,
        step3: `${row.step3_user_jobtitle?.job_title_id?.title}:  ${row.step3_user_jobtitle?.user_id?.first_name}`,
        worksCount: row.works_count,
        step1_user_jobtitle: row.step1_user_jobtitle,
        step2_user_jobtitle: row.step2_user_jobtitle,
        step3_user_jobtitle: row.step3_user_jobtitle,
        approve1: row.approve1,
        approve2: row.approve2,
        approve3: row.approve3,
        date: row.date_created.split("T")[0],
        user_canceled: row.user_canceled,
        user_canceled_chk: !!row.user_canceled,
        user_created: `${row.user_created.first_name} ${row.user_created.last_name}`,
      };
    });
    return updatedInfo;
  };

  useEffect(() => {
    userData && setTableData(getRows(orders));
  }, [orders, userData]);

  const clickDeleteHandler = async (e, id) => {
    try {
      if (!window.confirm(t("confirmDelete"))) {
        e.nativeEvent.stopImmediatePropagation();
      } else {
        await CrudService.removeItem(`items/orders/${id}`);
        // the delete does not send a response
        // so I need to get again the items to set it and this way the table gets updated -> it goes to the useEffect with data dependecy
        const response = await CrudService.getData(`${reqUrl}${filterUrl}`);
        setOrders(response.data);
        setNotification({
          value: true,
          text: t("deleteSuccessfully"),
        });
      }
    } catch (err) {
      // it sends error is the category is associated with an item
      console.error(err);
      if (err.hasOwnProperty("errors")) {
        setNotification({
          value: true,
          text: err.errors[0].title,
        });
      }
      return null;
    }
  };

  const cancelOrderHandler = async (id, uId) => {
    try {
      await CrudService.updateItem(`items/orders/${id}`, {
        user_canceled: uId,
      });
      const response = await CrudService.getData(`${reqUrl}${filterUrl}`);
      setOrders(response.data);
      setNotification({
        value: true,
        text: t("deleteSuccessfully"),
      });
    } catch (err) {
      // it sends error is the category is associated with an item
      console.error(err);
      if (err.hasOwnProperty("errors")) {
        setNotification({
          value: true,
          text: err.errors[0].title,
        });
      }
      return null;
    }
  };

  const dataTableData = {
    columns: [
      { Header: t("title"), accessor: "title", width: "15%" },
      { Header: "مقدم الطلب", accessor: "user_created", width: "15%" },
      {
        Header: t("step1users"),
        accessor: "step1",
        width: "15%",
        disableSortBy: true,
      },
      {
        Header: t("step2users"),
        accessor: "step2",
        width: "15%",
        disableSortBy: true,
      },
      {
        Header: t("step3users"),
        accessor: "step3",
        width: "15%",
        disableSortBy: true,
      },
      {
        Header: t("date"),
        accessor: "date",
        width: "15%",
        disableSortBy: true,
      },
      {
        Header: t("approve1"),
        accessor: "approve1",
        width: "15%",
        disableSortBy: true,
        Cell: (info) => {
          return (
            <Checkbox
              disabled={
                userData.id !==
                info.cell.row.original.step1_user_jobtitle.user_id.id
              }
              checked={info.cell.row.original.approve1}
              name="approve1"
              onChange={(e) =>
                handleChange(
                  e,
                  info.cell.row.original.user_canceled,
                  info.cell.row.original.id
                )
              }
            />
          );
        },
      },
      {
        Header: t("approve2"),
        accessor: "approve2",
        width: "15%",
        disableSortBy: true,
        Cell: (info) => {
          return (
            <Checkbox
              disabled={
                userData.id !==
                info.cell.row.original.step2_user_jobtitle.user_id.id
              }
              checked={info.cell.row.original.approve2}
              name="approve2"
              onChange={(e) =>
                handleChange(
                  e,
                  info.cell.row.original.user_canceled,
                  info.cell.row.original.id
                )
              }
            />
          );
        },
      },
      {
        Header: t("approve3"),
        accessor: "approve3",
        width: "15%",
        disableSortBy: true,
        Cell: (info) => {
          return (
            <Checkbox
              disabled={
                userData.id !==
                info.cell.row.original.step3_user_jobtitle.user_id.id
              }
              checked={info.cell.row.original.approve3}
              name="approve3"
              onChange={(e) =>
                handleChange(
                  e,
                  info.cell.row.original.user_canceled,
                  info.cell.row.original.id
                )
              }
            />
          );
        },
      },
      // {
      //   Header: t("cancel"),
      //   accessor: "user_canceled_chk",
      //   width: "15%",
      //   disableSortBy: true,
      //   Cell: (info) => {
      //     return (
      //       <Checkbox
      //         disabled
      //         checked={info.cell.row.original.user_canceled_chk}
      //         name="user_canceled"
      //       />
      //     );
      //   },
      // },
      {
        Header: t("cancel"),
        accessor: "user_canceled",
        width: "15%",
        disableSortBy: true,
        Cell: (info) => {
          return info.cell.row.original.user_canceled
            ? `${info.cell.row.original.user_canceled?.first_name} ${info.cell.row.original.user_canceled?.last_name}`
            : "";
        },
      },
      {
        Header: t("actions"),
        disableSortBy: true,
        accessor: "",
        Cell: (info) => {
          return (
            <MDBox display="flex" alignItems="center">
              {(ability.can("edit", "orders") || isAdmin) &&
                info.cell.row.original.worksCount === 0 && (
                  <Tooltip
                    title={t("updateOrder")}
                    onClick={(e) => clickEditHandler(info.cell.row.original.id)}
                  >
                    <IconButton>
                      <EyeIcon />
                    </IconButton>
                  </Tooltip>
                )}
              {/* {(ability.can("delete", "items") || isAdmin) &&
                info.cell.row.original.worksCount === 0 && (
                  <Tooltip
                    title={t("deleteItem")}
                    onClick={(e) =>
                      clickDeleteHandler(e, info.cell.row.original.id)
                    }
                  >
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )} */}
              {((userData.id ===
                info.cell.row.original.step1_user_jobtitle.user_id.id &&
                !info.cell.row.original.approve1 &&
                !info.cell.row.original.approve2 &&
                !info.cell.row.original.approve3) ||
                (userData.id ===
                  info.cell.row.original.step2_user_jobtitle.user_id.id &&
                  !info.cell.row.original.approve2 &&
                  !info.cell.row.original.approve3) ||
                (userData.id ===
                  info.cell.row.original.step3_user_jobtitle.user_id.id &&
                  !info.cell.row.original.approve3)) &&
                !info.cell.row.original.user_canceled && (
                  <Tooltip
                    title={t("cancel")}
                    onClick={
                      (e) =>
                        cancelOrderHandler(
                          info.cell.row.original.id,
                          userData.id
                        )
                      // clickEditHandler(info.cell.row.original.id, userData.id)
                    }
                  >
                    <IconButton>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                )}
            </MDBox>
          );
        },
      },
      ,
    ],
    rows: tableData,
  };

  return (
    <DefaultLayout>
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      <MDBox pt={3} pb={3} mb={3}>
        <Card>
          <MDBox
            p={3}
            lineHeight={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h5" fontWeight="medium">
              {t("orders")}
            </MDTypography>
            <MDBox display="flex">
              {(ability.can("create", "orders") || isAdmin) && (
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  onClick={clickAddHandler}
                  mx={1}
                >
                  + {t("addOrder")}
                </MDButton>
              )}
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                type="submit"
                onClick={() => setShowFilter(!showFilter)}
                sx={{ mx: 1 }}
              >
                {t("filter")}
              </MDButton>
            </MDBox>
          </MDBox>
          {showFilter && (
            <FilterOrder
              setFilterUrl={setFilterUrl}
              // filterUrl={defaultFilterUrl}
            />
          )}
          <DataTable
            setLimitChange={setLimitChange}
            table={dataTableData}
            meta={meta}
            setPage={setPage}
            setSortVal={setSortVal}
            pageNum={page}
            isSorted={false}
          />
        </Card>
      </MDBox>
    </DefaultLayout>
  );
}

export default Orders;
